import React from 'react'
import { Outlet } from 'react-router-dom'
import AdminSideBAr from './AdminSideBAr'

const AdminLayout = () => {
  return (
    <div className="flex flex-wrap">
      <AdminSideBAr/>
      <div className="flex-1 lg:ml-80 ">
        <Outlet />
      </div>
    </div>
  )
}

export default AdminLayout
