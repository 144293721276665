import React, { useEffect, useState } from "react";
import { FaCalendar, FaLocationArrow, FaLocationPin } from "react-icons/fa6";
import { TiLocation } from "react-icons/ti";
import { Destinations } from "../../components/Travel/Voyage";
import { FaBusAlt } from "react-icons/fa";
import { BiSolidUserAccount } from "react-icons/bi";
import { MdPayments } from "react-icons/md";
import { baseUrl } from "../../utils/constant_api";
import axios from "axios";
import moment from "moment/moment";
import "moment/locale/fr";
moment.locale("fr");

const Voyages = () => {
  const [dateDepart, setDateDepart] = useState("");
  const [villeDepart, setVilleDepart] = useState("");
  const [villeArrivee, setVilleArrivee] = useState("");
  const [voyages, setVoyages] = useState([]);
  const [voyagesData, setVoyagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const filteredVoyages = voyagesData.filter((voyage) => {
      return (
        voyage.ville_depart?.toLowerCase() === villeDepart?.toLowerCase() &&
        voyage.ville_arrive?.toLowerCase() === villeArrivee?.toLowerCase() &&
        voyage.date_de_depart.split(" ")[0] === dateDepart
      );
    });
    setVoyages(filteredVoyages);
    setIsComplete(true);
    setLoading(false);
  };

  const bookTravel = (voyage) => {
    window.location.href = `/voyage/${voyage.ville_depart}/${voyage.ville_arrive}/${voyage.id}`;
  };

  useEffect(() => {
    getTrip();
  }, []);

  const getTrip = async () => {
    await axios.get(`${baseUrl}/voyages`).then((res) => {
      setVoyagesData(res.data);
    });
  };

  return (
    <div className="py-4">
      <div className="pt-10 px-6 lg:px-36 mb-4 inline-block bg-blue-700 h-content w-full">
        <div className="w-full md:w-2/3 lg:w-1/3 text-start mt-10">
          <h1 className="text-white text-3xl font-bold">
            Payez en toute sécurité votre billet de voyage sur notre plateforme
          </h1>
          <h1 className="text-white text-2xl font-semibold">Numéro service : 698 427 176</h1>
          
        </div>

        <div className="bg-white p-4 mt-10 rounded-xl shadow-xl ">
          <div className="container mx-auto w-full">
            <form
              onSubmit={handleSubmit}
              className="md:flex justify-center w-full gap-2"
            >
              <div className="relative mb-2 w-full">
                <TiLocation className='"w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto' />
                <select
                  className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                  id="villeDepart"
                  name="villeDepart"
                  value={villeDepart}
                  onChange={(e) => setVilleDepart(e.target.value)}
                >
                  <option value="">Ville de depart</option>
                  {Destinations.map((destination) => (
                    <option key={destination.id} value={destination.name}>
                      {destination.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="relative mb-2 w-full">
                <FaLocationArrow className='"w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto' />
                <select
                  className="w-full pl-12 pr-3 py-2 outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                  id="villeArrivee"
                  name="villeArrivee"
                  value={villeArrivee}
                  onChange={(e) => setVilleArrivee(e.target.value)}
                >
                  <option value="" className="text-gray-500">
                    ville d'arriver
                  </option>
                  {Destinations.map((destination) => (
                    <option key={destination.id} value={destination.name}>
                      {destination.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="relative mb-2 w-full">
                <FaCalendar className='"w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto' />
                <input
                  type="date"
                  min={new Date().toISOString().slice(0, 10)}
                  id="dateVoyage"
                  name="dateVoyage"
                  placeholder="Date de voyage"
                  value={dateDepart}
                  onChange={(e) => setDateDepart(e.target.value)}
                  className="w-full pl-12 pr-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                />
              </div>

              {/* <div className="relative max-w-xs mb-2 w-full">
                <FaUsers className='"w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto' />
                <input
                  type="number"
                  id="nbPlaces"
                  name="nbPlaces"
                  placeholder="Nombre de places"
                  value={nombrePlaces}
                  onChange={(e) => setNombrePlaces(e.target.value)}
                  className="w-full pl-12 pr-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                />
              </div> */}
              <div className="">
                <button
                  type="submit"
                  className="bg-blue-600 text-white rounded p-2 w-full"
                >
                  Rechercher
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="mb-20">
        <div className="bg-white-900 mb-8">
          <div className="relative py-2 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16 px-2">
            {loading ? (
              <i className="fa fa-spinner mt-2 text-danger fa-spin fa-2x"></i>
            ) : (
              <div></div>
            )}

            {isComplete && voyages.length === 0 && (
              <div>
                <div>
                  <h1 className="font-bold text-2xl text-slate-500">
                    Désolé...aucun résultat
                  </h1>
                </div>
                <div>
                  <h1 className="font-medium text-lg text-slate-500">
                    {" "}
                    Nous n'avons trouvé aucun bus qui correspond à vos critères
                    de recherche.
                  </h1>
                </div>
              </div>
            )}
            {isComplete && voyages.length >= 1 && (
              <div>
                <div>
                  <h1 className="font-bold text-2xl text-slate-700">
                    Voyages trouvés
                  </h1>
                </div>
                <div>
                  <h1 className="font-medium text-slate-500">
                    {" "}
                    Selectionnez le voyage qui vous convient en cliquant dessus.
                  </h1>
                </div>
              </div>
            )}

            {voyages?.map((voyage, i) => (
              <article
                className="rounded-xl border-2 px-2 cursor-pointer py-2 border-gray-200 shadow-xl bg-white mt-4 lg:w-3/4 mx-auto"
                key={i}
                onClick={() => bookTravel(voyage)}
              >
                <div className="items-start gap-2 p-2 sm:p-6 lg:p-8">
                  <div className="mx-2 flex justify-between w-full">
                    <div className="flex justify-start items-center gap-2">
                      <img
                        src={voyage.logo_agence}
                        alt=""
                        className="h-8 w-10"
                      />
                      <h3 className="uppercase text-gray-600 font-bold">
                        {voyage.agence}
                      </h3>
                    </div>
                    <div className="mt-2 flex justify-start text-start">
                      <p className="mx-4 font-bold text-blue-700 mt-2">
                        {voyage.type_voyage}
                      </p>
                    </div>
                  </div>

                  <div className="flex w-full">
                    <p className="text-sm text-left w-1/6 mx-3 font-semibold text-red-600">
                      Départ :
                    </p>
                    <p className="text-sm font-semibold text-blue-600">
                    <span className="text font-medium mr-1 text-red-600">
                          {voyage.ville_depart}
                        </span>
                      ({voyage.lieu_depart})
                    </p>
                  </div>
                  <div className="flex w-full">
                    <p className="text-sm text-left w-1/6 marker: mx-3 font-semibold text-red-600">
                      Arrêt : 
                    </p>
                    <p className="text-sm font-semibold text-blue-600">
                    <span className="text font-medium mr-1 text-red-600">
                          {voyage.ville_arrive}
                        </span>
                      ({voyage.arret})
                    </p>
                  </div>

                  <div className=" flex mt-2 justify-between px-3 items-center">
                    <div>
                      <p className="text font-bold text-blue-600">
                        {moment(voyage.date_de_depart).format("dddd Do MMMM ")}
                      </p>
                    </div>
                    <div className="inline-block">
                      <div className="flex items-center gap-1 text-gray-700">
                        <span className="text-sm font-medium text-white rounded-md p-2 bg-blue-600 ">
                          {voyage.net_a_payer} FCFA
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 flex sm:items-center sm:gap-2 justify-between w-full items-center">
                    <div className="inline-block">
                      <div className="flex items-center gap-1 text-gray-700">
                        <FaLocationPin />
                        <p className="text font-medium text-blue-600">
                          {voyage.ville_depart}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="text-xs font-medium">Heure Départ</p>
                      <p className="text-sm font-medium p-4 py-1  text-white rounded-md bg-red-700 ">
                        {voyage.heure_depart.split("", 5)}
                      </p>
                    </div>

                    <div className="inline-block">
                      <div className="flex items-center gap-1 text-gray-700">
                        <FaLocationPin />
                        <p className="text font-medium text-blue-600">
                          {voyage.ville_arrive}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-2">
                    <p className="text-xs mt-1 font-medium text-red-600">
                      {voyage.nombre_place_dispo} places restantes
                    </p>
                  </div>
                </div>

                {/* <div className="flex justify-center">
                  <span
                    className="text-[10px] font-medium text-base border-blue-600 cursor-pointer border-1 px-8 py-3 rounded-xl w-full mx-4 mt-4"
                    // onClick={}
                  >
                    en savoir plus
                  </span>
                </div> */}
              </article>
            ))}
          </div>
        </div>
      </div>

      <div className="pt-10 px-6 lg:px-36 mt-20">
        <h1 className="text-3xl font-bold ">Comment procéder ?</h1>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:items-stretch md:grid-cols-3 md:gap-8 pt-10">
          <div className="mx-4 mt-4">
            <FaBusAlt className="w-20 h-20 text-blue-700 mx-auto mb-2" />
            <p className="font-medium text-sm">
              {" "}
              Vérifiez les horaires et tarifs des billets de bus, puis
              sélectionnez le voyage qui vous convient
            </p>
          </div>
          <div className="mx-4 mt-4">
            <BiSolidUserAccount className="w-20 h-20 text-blue-700 mx-auto mb-2" />
            <p className="font-medium text-sm">
              {" "}
              Entrez les informations des passagers et du payeur
            </p>
          </div>
          <div className="mx-4 mt-4">
            <MdPayments className="w-20 h-20 text-blue-700 mx-auto mb-2" />
            <p className="font-medium text-sm">
              {" "}
              Vérifiez les détails et procédez au paiement{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voyages;
