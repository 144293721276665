import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";


const AdminMetiers = ({ metiers }) => {
  const [open, setOpen] = useState(false);
  const [titre, setTitre] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    const token = localStorage.getItem("token");
    event.preventDefault();
    setLoading(true)
    axios
      .post(
        `${baseUrl}/metier/new`,
        { titre, description },
        {
           headers: {
          Authorization: `Bearer ${token}`,
        },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200 ) {
          setLoading(false)
          setOpen(false);
          window.location.reload(); 
        } else {
          setLoading(false);
          setError();
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.message);
      });
  };

  return (
    <div className="mt-8 py-8">
      <div className="mt-4">
        <button className="button ml-5" onClick={() => handleClickOpen()}>
          Créer un Domaine
        </button>
        <div className="tableContain paddings flexCenter ">
          <TableContainer component={Paper} className="table">
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">ID</TableCell>
                  <TableCell className="tableCell m-5">Domaines</TableCell>
                  <TableCell className="tableCell">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metiers?.map((metier, i) => (
                  <TableRow key={i}>
                    <TableCell className="tableCell">{metier.id}</TableCell>
                    <TableCell className="tableCell">
                      <div className="cellWrapper">{metier.titre}</div>
                    </TableCell>
                    <TableCell className="tableCell">
                      <div className="">
                        <Link className="border rounded px-4 bg-red-500 text-white py-2">
                          Supprimé
                        </Link>
                        <Dialog open={open} onClose={handleClose}>
                          <DialogTitle>Créer un Domaine d'activité</DialogTitle>
                          <DialogContent>
                            <TextField
                              autoFocus
                              margin="dense"
                              name="name"
                              value={titre}
                              onChange={(e) => setTitre(e.target.value)}
                              label="Nom du Domaine"
                              type="text"
                              fullWidth
                              variant="standard"
                            />
                            <TextField
                              autoFocus
                              margin="dense"
                              name="description"
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              label="Description"
                              type="text"
                              fullWidth
                              variant="standard"
                            />
                          </DialogContent>
                          {loading ? (
                            <CircularProgress />
                          ) : (
                            <DialogActions>
                              <Button onClick={handleClose}>Annuler</Button>
                              <Button onClick={handleSubmit}>Créer</Button>
                            </DialogActions>
                          )}
                        </Dialog>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default AdminMetiers;
