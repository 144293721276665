import React from 'react'

const Hero = () => {
  return (
    <div className=" hidden lg:flex  relative  flex-col-reverse py-16 lg:pt-0 lg:flex-col lg:pb-0  mt-4 2xl:mt-20 bg-blue-800">
      <div className="inset-y-0 top-0 right-0 z-0 w-full max-w-xl px-4 mx-auto md:px-0 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-7/12 lg:max-w-full lg:absolute xl:px-0 ">
        <svg
          className="absolute left-0 hidden h-full text-white transform -translate-x-1/2 lg:block"
          viewBox="0 0 100 100"
          fill="currentColor"
          preserveAspectRatio="none slice"
        >
          <path d="M50 0H100L50 100H0L50 0Z" />
        </svg>
        <img
          className="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none md:h-96 lg:h-full"
          src="./images/architectes.jpg"
          alt=""
        />
      </div>
      <div className="relative flex flex-col items-start w-full max-w-xl px-4 mx-auto md:px-0 lg:px-8 lg:max-w-screen-xl">
        <div className=" bg-white p-4 mb-16 lg:my-40 lg:max-w-lg lg:pr-5 rounded-lg items-center">
          
          <h2 className="mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            Bienvenue Chez Emploi Monde
          </h2>
          <p className="pr-5 mb-5 text-base text-gray-700 md:text-lg">
          Découvrez un monde de possibilités professionnelles et de voyages passionnants grâce à notre plateforme tout-en-un.
          </p>
          <div className="flex items-center">
            <a
              href="/"
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-600 hover:bg-purple-700 focus:shadow-outline focus:outline-none"
            >
             DEBUTER
            </a>
            <a
              href="/"
              aria-label=""
              className="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-gray-900 hover:bg-purple-700 focus:shadow-outline focus:outline-none"
            >
              RECHERCHER
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
