import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header/Header'
import BottomNavigation from './ClientDashboard/BottomNavigation'


const Layout = ({authUser}) => {
  return (
    <div className=''>
     <Header  authUser = {authUser}r/>
     <Outlet/>
     <BottomNavigation/>
    </div>
  )
}

export default Layout
 