import React from 'react'
import Hero from '../../components/Hero/Hero'
import Content from '../../components/Content/Content'
// import BottomNavigation from '../../components/ClientDashboard/BottomNavigation'
import Hero2 from '../../components/Hero/Hero2'
const Home = ({metiers}) => {
  return (
    <div className='Home'>
      <Hero/>
      <Hero2/>
      <Content/>
      {/* <BottomNavigation/> */}
    </div>
  )
}

export default Home
