import React from 'react'
import { Link } from 'react-router-dom'
// import OfferCard from '../../components/Card/OfferCard'

const OfferPage = ({authUser}) => {
  return (
    <div className='py-20'>
      <div className='mt-2 w-3/4 mx-auto max-w-lg'>
        <Link className='bg-blue-600 text-white rounded-lg px-4 py-2 inline-block mb-2 w-full' to ='/complete-profil'>S'enregistrer en tant que chercheur d'emploi</Link>
        <Link className='bg-blue-600 text-white rounded-lg px-4 py-2 inline-block mb-2 w-full' to = '/technicien-register'>S'enregistrer en tant que technicien</Link>
        <Link className='bg-blue-600 text-white rounded-lg px-4 py-2 inline-block mb-2 w-full' to = '/service-register'>S'enregistrer en tant que prestataire de service</Link>
      </div>
        {/* <OfferCard authUser = {authUser}/> */}
    </div>
  )
}

export default OfferPage