import React from "react";
import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { Link } from "react-router-dom";

const Header = ({ authUser }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const goToDashboard = () => {
    if (authUser !== null && authUser !== undefined) {
      window.location.href = "/profil-page";
    } else {
      window.location.href = "/login";
    }
  };

  return (
    <div className="fixed top-0 z-10 header w-full bg-gray-900 ">
      <div className="px-5 py-3 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div className="relative flex items-center justify-between">
          <Link to="/" className="inline-flex items-center">
            <img
              src="./images/emploi-monde.png"
              alt=""
              className="w-8 text-teal-accent-400"
            />
            <span className="ml-2 text-xl font-bold tracking-wide text-gray-100 uppercase">
              EMPLOI MONDE
            </span>
          </Link>
          <ul className=" items-center hidden space-x-6 lg:flex">
            <li>
              <Link
                to="/partenaires"
                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Partenaires
              </Link>
            </li>
            <li>
              <Link
                to="/politique-confidentialite"
                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                pricacy
              </Link>
            </li>
            <li>
              <Link
                to="/travel"
                className="font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:text-teal-accent-400"
              >
                Voyages
              </Link>
            </li>
            <li>
              <h1
                className="font-medium flex tracking-wide items-center text-gray-100 transition-colors duration-200 hover:text-teal-accent-400 cursor-pointer"
                onClick={goToDashboard}
              >
                <AiOutlineUser className="items-center text-base mx-1" />
                <p>Mon compte</p>
              </h1>
            </li>
            <li>
              <Link
                to="/recruteur-register"
                className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-700 hover:bg-bue-800 focus:shadow-outline focus:outline-none"
              >
                Espace Recruteur
              </Link>
            </li>
          </ul>

          <div className="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg className="w-5 text-white" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div className="absolute top-0 left-0 w-full z-50">
                <div className="p-5 bg-white border rounded shadow-sm">
                  <div className="flex items-center justify-between mb-4">
                    <div>
                      <a
                        href="/"
                        aria-label="Company"
                        title="Company"
                        className="inline-flex items-center"
                      >
                        <img
                          src="./images/emploi-monde.png"
                          alt=""
                          className="w-8 text-teal-accent-400"
                        />
                        <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                          EMPLOI MONDE
                        </span>
                      </a>
                    </div>
                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul className="space-y-4">
                      {!authUser && (
                        <li>
                          <a
                            href="/offre"
                            className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                          >
                            Trouver un emploi
                          </a>
                        </li>
                      )}

                      <li>
                        <a
                          href="/employers"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Trouver un employer
                        </a>
                      </li>
                      <li>
                        <a
                          href="/services"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Services
                        </a>
                      </li>
                      <li>
                        <a
                          href="/travel"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          VOYAGE
                        </a>
                      </li>
                      <li>
                        <a
                          href="/partenaires"
                          className="font-medium tracking-wide text-gray-700 transition-colors duration-200 hover:text-teal-accent-400"
                        >
                          Partenaires
                        </a>
                      </li>
                      <li>
                        <h1
                          className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-950 hover:bg-blue-800 focus:shadow-outline focus:outline-none cursor-pointer"
                          onClick={goToDashboard}
                        >
                          Mon Compte
                        </h1>
                      </li>
                      <li>
                        <a
                          href="/recruteur-register"
                          className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-blue-950 hover:bg-purple-700 focus:shadow-outline focus:outline-none"
                        >
                          Espace recruteur
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
