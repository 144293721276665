import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy container text-left py-8 mt-16 space-y-4">
      <h1 className="font-bold text-2xl">Politique de confidentialité</h1>
      <p>
        Emploi Monde prend au sérieux la confidentialité de ses utilisateurs .
        Cette politique de confidentialité décrit comment nous collectons,
        utilisons et divulguons vos informations personnelles lorsque vous
        utilisez l'Application.
      </p>
      <p>
        Veuillez lire attentivement cette Politique avant d'utiliser
        l'Application. En utilisant l'Application, vous acceptez les conditions
        de cette Politique. Si vous n'acceptez pas les conditions de cette
        Politique, vous ne devez pas utiliser l'Application.
      </p>
      <p>
        Veuillez lire attentivement cette Politique avant d'utiliser
        l'Application. En utilisant l'Application, vous acceptez les conditions
        de cette Politique. Si vous n'acceptez pas les conditions de cette
        Politique, vous ne devez pas utiliser l'Application.
      </p>
      <h1 className="font-bold text-xl">
        {" "}
        Collecte d'informations personnelles
      </h1>
      <p>
        Nous collectons les informations personnelles suivantes lorsque vous
        utilisez l'Application :
      </p>
      <p>
        <span className="font-bold">
          {" "}
          Informations que vous nous fournissez directement :{" "}
        </span>{" "}
        Cela inclut les informations que vous nous fournissez lorsque vous créez
        un compte, telles que votre nom, votre adresse e-mail et votre mot de
        passe. Cela peut également inclure des informations supplémentaires que
        vous nous fournissez ultérieurement, telles que votre profil
        professionnel et vos intérêts.
      </p>
      <p>
        <span className="font-bold">
          Informations collectées automatiquement :{" "}
        </span>{" "}
        Lorsque vous utilisez l'Application, nous recueillons automatiquement
        certaines informations sur votre appareil et votre activité. Cela inclut
        des informations telles que votre adresse IP, votre système
        d'exploitation, votre type de navigateur et les pages que vous visitez
        sur l'Application. Nous pouvons également collecter des informations sur
        votre activité sur l'Application, telles que les recherches que vous
        effectuez et les contacts que vous prenez avec d'autres utilisateurs.
      </p>
      <p>
        <span className="font-bold">
          Informations provenant de sources tierces :{" "}
        </span>{" "}
        Nous pouvons collecter des informations vous concernant auprès de
        sources tierces, telles que les réseaux sociaux. Cela se produit lorsque
        vous nous autorisez à le faire ou lorsque ces informations sont
        accessibles au public.
      </p>
      <h1 className="font-bold text-xl">
        Utilisation des informations personnelles
      </h1>
      <p>Nous utilisons vos informations personnelles aux fins suivantes :</p>
      <p>
        <span className="font-bold">
          Pour vous fournir l'Application et ses services :{" "}
        </span>{" "}
        Nous utilisons vos informations personnelles pour vous fournir les
        fonctionnalités et les services de l'Application. Cela inclut la
        création de votre compte, la fourniture de recommandations et la
        connexion avec d'autres utilisateurs.
      </p>
      <p>
        <span className="font-bold">Pour améliorer l'Application : </span> Nous
        utilisons vos informations personnelles pour améliorer l'Application et
        ses services. Cela inclut la correction de bogues, le développement de
        nouvelles fonctionnalités et la personnalisation de votre expérience.
      </p>
      <p>
        <span className="font-bold">Pour vous contacter : </span> Nous pouvons
        utiliser vos informations personnelles pour vous contacter au sujet de
        l'Application ou pour vous fournir des informations marketing. Vous
        pouvez vous désabonner de ces communications à tout moment en contactant
        notre support client.
      </p>
      <h1 className="font-bold text-xl">
        Partage des informations personnelles
      </h1>
      <p>
        Nous pouvons partager vos informations personnelles avec les tiers
        suivants :
      </p>
      <p>
        <span className="font-bold">Fournisseurs de services :</span> Nous
        pouvons partager vos informations personnelles avec des fournisseurs de
        services qui nous aident à fournir l'Application et ses services. Ces
        fournisseurs de services sont tenus de protéger vos informations
        personnelles conformément à cette Politique.
      </p>
      <p>
        <span className="font-bold">Partenaires commerciaux : </span> Nous
        pouvons partager vos informations personnelles avec des partenaires
        commerciaux qui nous aident à vous offrir des produits et services
        pertinents. Ces partenaires commerciaux sont tenus de protéger vos
        informations personnelles conformément à cette Politique.
      </p>
      <p>
        <span className="font-bold">Autorités gouvernementales : </span> Nous
        pouvons partager vos informations personnelles avec les autorités
        gouvernementales si la loi l'exige ou si nous pensons de bonne foi que
        cela est nécessaire pour protéger nos droits ou les droits de tiers.
      </p>
      <h1 className="font-bold text-xl">
        {" "}
        Sécurité des informations personnelles
      </h1>
      <p>
        Nous prenons des mesures de sécurité appropriées pour protéger vos
        informations personnelles contre la perte, l'utilisation abusive et
        l'accès non autorisé. Cependant, aucune méthode de sécurité n'est
        parfaite et nous ne pouvons pas garantir la sécurité absolue de vos
        informations personnelles.
      </p>
      <h1 className="font-bold text-xl"> Modifications de cette politique</h1>
      <p>
        Nous pouvons modifier cette Politique de temps à autre. Nous vous
        informerons de toute modification en publiant la politique révisée sur
        l'Application. Les modifications entreront en vigueur dès leur
        publication, sauf indication contraire dans la politique révisée.
      </p>
      <h1 className="font-bold text-xl">Nous contacter</h1>
      Si vous avez des questions ou des commentaires concernant les présentes
      Conditions d’Utilisation, veuillez nous contacter l'adresse
      fokamfranckgemael@gmail.com ou par telephone au 698427176
    </div>
  );
};

export default PrivacyPolicy;
