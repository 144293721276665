import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../../utils/constant_api";
import { IoIosArrowBack } from "react-icons/io";
import { Dialog } from "@mui/material";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaCheckCircle } from "react-icons/fa";

const OfferDetails = () => {
  const [loading, setLoading] = useState(false);
  const [offre, setOffre] = useState({});
  const { id } = useParams();

  const [echec, setEchec] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState("");

  const [file, setFile] = useState(null);

  const handleClose = () => {
    setOpen(false);
    window.location.href = "/client-monespace";
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const formData = new FormData();
    if (file !== null && file !== undefined) {
      formData.append("cv", file);
    }
    formData.append("offre_id", id);
    axios
      .post(`${baseUrl}/candidature/new`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          setOpen(true);
          setAlert("succèss");
          setMessage(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setEchec(true)
        console.log(error);
        setAlert("succèss");
        setMessage(error.data);
      });
  };

  useEffect(() => {
    const getOffer = () => {
      axios.get(`${baseUrl}/offre/${id}`).then((res) => {
        setOffre(res.data);
      });
    };
    getOffer();
  }, [id]);

  return (
    <div className="pb-24 relative">
      <div className="py-6 px-6 lg:px-36 mb-4 inline-block bg-blue-700 h-content w-full fixed top-0 left-0 lg:left-24 z-10">
        <div className="w-full md:w-2/3 lg:w-1/3 text-start lg:ml-24 flex items-center">
          <IoIosArrowBack size={25} color="white" onClick={goBack} />
          <h1 className="text-white text-xl font-medium mx-2">
            Detail de l'offre
          </h1>
        </div>
      </div>

      <div className="lg:flex">
        <div className="lg:w-3/4">
          <div className="p-2 mt-28">
            <div className="flex justify-start">
              <h1 className="font-bold text-2xl text-gray-600">
                {offre?.titre}
              </h1>
            </div>
            <div className="flex justify-start">
              <h1 className="font-medium text-lg text-gray-500 mx-2">
                {offre?.metier?.titre}
              </h1>{" "}
              |
              <h1 className="font-medium text-lg text-gray-500 mx-2">
                {offre?.type_contrat}
              </h1>{" "}
              |
              <h1 className="font-medium text-lg text-gray-500 mx-2">
                {offre?.localisation}
              </h1>
            </div>
          </div>
          <hr className="mx-2 mt-2" />

          <div className="p-2">
            <div className="flex justify-start my-4">
              <h1 className="font-bold text-2xl text-gray-600">Description</h1>
            </div>

            <div className="flex justify-start my-4 p-2">
              <p
                className="text-start text-lg"
                dangerouslySetInnerHTML={{ __html: offre.description }}
              ></p>
            </div>
          </div>

          <div className="p-2">
            <div className="flex justify-start my-4">
              <h1 className="font-bold text-2xl text-gray-600">
                Profil Recherché
              </h1>
            </div>

            <div className="flex justify-start my-4 p-2">
              <p
                className="text-start text-lg"
                dangerouslySetInnerHTML={{ __html: offre.profile_recherche }}
              ></p>
            </div>
          </div>
        </div>

        <div className="lg:w-1/4 p-2 lg:mt-28  border">
          <div className="p-2">
            <div className="flex justify-start ">
              <h1 className="font-bold text-2xl text-gray-600">
                {offre?.titre}
              </h1>
            </div>
            <div className="flex justify-start flex-row">
              <h1 className="font-medium text-lg text-gray-500 mx-2">
                {offre?.metier?.titre}
              </h1>{" "}
              |
              <h1 className="font-medium text-lg text-gray-500 mx-2">
                {offre?.type_contrat}
              </h1>{" "}
              |
              <h1 className="font-medium text-lg text-gray-500 mx-2">
                {offre?.localisation}
              </h1>
            </div>
          </div>

          <div>
            <label
              htmlFor="file-input"
              className="border-2  mt-2 py-2 px-4 border-blue-600"
            >
              Téléchager un cv
            </label>
            <div>
              <span className="text-red-600 text-sm">Taille max: 5Mo</span>
            </div>
            <input type="file" id="file-input" onChange={handleChange} hidden />
            {file && <p>{file.name}</p>}
          </div>

          {loading ? (
            <i className="fa fa-spinner mt-2 text-danger fa-spin fa-2x"></i>
          ) : (
            <button
              className="bg-blue-700 text-white mt-2 rounded-xl w-full p-2"
              onClick={handleSubmit}
            >
              Postuler
            </button>
          )}
        </div>
      </div>
      <div>
        <Dialog open={open}>
          <div
            role="alert"
            className="rounded-xl border border-gray-100 bg-white p-4"
          >
            <div>
              <div className="flex items-center justify-center">
                <span className="text-green-600">
                  {echec ? (
                    <RiCloseCircleFill size={35} color="red" />
                  ) : (
                    <FaCheckCircle size={35} color="green" />
                  )}
                </span>
                <strong className="block font-bold mx-4"> {alert} </strong>
              </div>

              <p className="mt-1 text-sm text-gray-700">{message}</p>
            </div>
            <div className="flex justify-center mx-auto">
              <button
                className={
                  echec
                    ? "px-10 py-2 border bg-red-600 text-white rounded-xl"
                    : "px-10 py-2 border bg-green-600 text-white rounded-xl"
                }
                onClick={handleClose}
              >
                Fermer
              </button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default OfferDetails;
