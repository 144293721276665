import React from "react";
import { baseUrl } from "../utils/constant_api";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const VerifyOtp = () => {
  const navigate = useNavigate();
  const phoneNumber = localStorage.getItem("phoneNumber");
  const password = localStorage.getItem("password");
  
  const [errorMessage, setError] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault();
    setSuccess(false);
    setLoading(true);
    const formData = new FormData();
    formData.append("email", phoneNumber);
    formData.append("password", password);
    axios
      .post(`${baseUrl}/verify-otp`, { phoneNumber: phoneNumber, otp: otp })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          axios
          .post(`${baseUrl}/auth/login`, formData)
          .then((res) => {
            if (res.status === 200 || res.status === 201) {
              localStorage.setItem("token", res.data.access_token);
              const token = localStorage.getItem("token");
              axios
                .get(`${baseUrl}/auth/me`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    localStorage.setItem('userRole', response.data.type)
                    if(response.data.type === "admin") {
                    navigate("/admin")
                    } else {
                      navigate("/profil-page")
                      setLoading(false);
                    }
            }});
            } else {
              setError(res.response.data.errors);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setError(error.response?.data?.errors);
            setLoading(false);
          });
        } else {
            setError(res.data.message);
            setLoading(false);
        }
      })
      
  };

  const resetOtp = (e) => {
    e.preventDefault()
    setLoading(true);
    axios
      .post(`${baseUrl}/send-otp`, { phoneNumber: phoneNumber })
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.data.status === "ok") {
          setSuccess(true);
          setError(res.data.message);
        } else {
          console.log(res);
          setError(res.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div className="login paddinds container">
      <div className="paddings w-full max-w-xl shadow-lg border rounded-lg my-10 gap-4 mx-2">
        <div className="mx-auto rounded-full border shadow-lg w-32 h-32 items-center flex">
          <img
            src="images/emploi-monde.png"
            alt="logo"
            className="object-cover w-full h-full"
          />
        </div>

        <form
          action="flex justify-center w-full"
          className=""
          onSubmit={(e) =>handleSubmit(e)}
        >
          <div className="w-full p-4">
            <h3 className="font-semibold text-xl text-center ">
              Vérification du numéro de téléphone
            </h3>
            <h3 className="text-center text-gray-600 font-medium mb-2">
              Nous vous avons envoyer un message au numéro {phoneNumber}
            </h3>
            {errorMessage !== "" && !success ? (
              <div className="btn btn-outline-danger w-53 my-1 align-items-center">
                <i className="fa fa-warning"></i> {errorMessage}
              </div>
            ) : (
              <span></span>
            )}
            {errorMessage !== "" && success ? (
              <div className="btn btn-outline-success w-53 my-1 align-items-center">
                <i className="fa fa-check-circle fa-3x"></i> {errorMessage}
              </div>
            ) : (
              <span></span>
            )}
            <div className="w-full mt-3 mb-2">
              <input
                type="text"
                className="w-full p-2 border rounded-md bg-gray-100"
                name="otp"
                value={otp}
                placeholder="Entrez le code de confirmation"
                onChange={(e) => setOtp(e.target.value)}
              />
            </div>{" "}
            {loading ? (
              <i className="fa fa-spinner fa-spin fa-2x"></i>
            ) : (
              <button className="w-full bg-blue-700 rounded-md text-white p-2">
                Confirmer
              </button>
            )}
            <div className="mt-4 w-full flex justify-center">
              <span
                className="text-center text-blue-600 cursor-pointer border-1 py-1.5 px-3 rounded-md border-blue-600"
                onClick={() =>resetOtp()}
              >
                Envoyer a nouveau
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default VerifyOtp;
