import { Dialog } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { baseUrl } from "../utils/constant_api";

const MaisonFilter = ({ profiles }) => {
  const [candidats, setCandidats] = useState([]);
  const metierName = useParams();

  const conctat = +237698427176;

  const [open, setOpen] = useState(false);
  const [cv, setCv] = useState(null);

  const openCv = (candidatCV) => {
    setCv(candidatCV);
    setOpen(true);
  };

  const handleDownloadCV = (candidat) => {
    if (candidat && candidat.id) {
      window.location.href = `${baseUrl}/download-cv/${candidat.id}`;
      setOpen(true);
    } else {
      alert("CV non disponible");
    }
  };

  const getCandidats = () => {
    if (metierName !== undefined) {
      setCandidats(
        profiles.filter(
          (profile) =>
            (profile.type === "employe" &&
              profile?.profil?.metier?.titre === metierName.metierName) ||
            profile?.profil?.metier2?.titre === metierName.metierName ||
            profile?.profil?.metier3?.titre === metierName.metierName
        )
      );
    }
  };

  useEffect(() => {
    getCandidats();
  }, []);

  return (
    <div>
      <div className="py-20">
        <div className="w-full">
          <Dialog open={open}>
            <div
              role="alert"
              className="rounded-xl  min-w-full border-gray-100 bg-white p-4 w-full"
            >
              <p>Téléchagement en cours, vérifiez vos notifications</p>

              <div className="flex justify-center mt-4">
                <button
                  className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                  onClick={() => setOpen(false)}
                >
                  Fermer
                </button>
              </div>
            </div>
          </Dialog>
        </div>
        <div>
          {candidats.map((candidat, i) => (
            <div
              className="relative block overflow-hidden rounded-lg border border-gray-100 p-4 sm:p-6 max-w-xl mx-auto mt-2 mb-4"
              key={i}
            >
              <span className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-green-300 via-blue-500 to-purple-600"></span>

              <div className="flex border-black border-b-2 justify-between mb-2 p-2 items-center">
                <span className="font-bold uppercase">
                  {candidat?.firstName} {candidat?.lastName}{" "}
                </span>
              </div>
              <div className="gap-4">
                <div className="w-40 h-40 flex mx-auto my-2">
                  <img
                    className="rounded-full object-cover w-full h-full"
                    alt=""
                    src={candidat?.images[0]}
                  />
                </div>
                <div className="w-full pt-3">
                  <div className="flex  items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Langue : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {candidat?.profil?.langueprincipale}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Diplome : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2 text-left">
                        {candidat?.profil?.diplome}
                      </span>
                    </div>
                  </div>

                  <div className="flex items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Metier : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold text-blue-600 mx-2">
                        {candidat?.profil?.metier?.titre}
                      </span>
                    </div>
                  </div>

                  {candidat?.profil?.metier2 && (
                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium w-full text-left">
                          Compétences 2:{" "}
                        </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2 w-full text-left text-blue-600">
                          {candidat?.profil?.metier2?.titre},
                        </span>
                      </div>
                    </div>
                  )}

                  {candidat?.profil?.metier3 && (
                    <div className="flex items-center">
                      <div className="w-1/3 flex">
                        <span className="text-sm font-medium w-full text-left">
                          Compétences 3:{" "}
                        </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span className="text-sm font-bold mx-2 w-full text-left text-blue-600">
                          {candidat?.profil?.metier3?.titre}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="flex items-center">
                    <div className="w-1/3 flex">
                      <span className="text-sm font-medium">Residence : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {candidat?.profil?.quatier}, {candidat?.profil?.ville}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center ">
                    <div className="w-1/3 flex items-start justify-start">
                      <span className="text-sm font-medium">Naisssance : </span>
                    </div>
                    <div className="w-2/3 flex">
                      <span className="text-sm font-bold mx-2">
                        {candidat?.profil?.datenais}
                      </span>
                    </div>
                  </div>

                  {candidat.cv && (
                    <div className="flex items-center mt-2">
                      <div className="w-1/3 flex items-start justify-start">
                        <span className="text-sm font-bold">CV : </span>
                      </div>
                      <div className="w-2/3 flex">
                        <span
                          onClick={() => handleDownloadCV(candidat)}
                          className="px-4 py-2 border-1 border-blue-700 rounded-lg"
                        >
                          Télécharger le CV
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-4">
                <p className="text-pretty text-sm text-gray-600 text-justify">
                  {candidat?.profil?.biographie}
                </p>
              </div>

              <div className="mt-6 flex gap-4 sm:gap-6 items-center justify-between">
                <div className="flex flex-col-reverse">
                  <dt className="text-sm font-medium text-gray-600">
                    {candidat?.profil?.updated_at.split("T")[0]}
                  </dt>
                  <dd className="text-xs text-gray-500">Denière mise à jour</dd>
                </div>
                <div>
                  <Link
                    className="border-1 rounded-lg text-blue-600 cursor-pointer px-4 border-blue-600"
                    to={`https://wa.me/${conctat}?text=Bonjour emploi monde Besoin de votre employé  ${
                      candidat.firstName + " " + candidat.lastName
                    } / ${candidat?.profil?.metier?.titre}`}
                    target="_blank"
                  >
                    en savoir plus
                  </Link>
                </div>
              </div>
            </div>
          ))}
          {candidats.length === 0 && (
            <div>
              <div>
                <h1 className="font-bold text-2xl text-slate-500">
                  Désolé...aucun résultat
                </h1>
              </div>
              <div>
                <h1 className="font-medium text-lg text-slate-500">
                  {" "}
                  Nous n'avons pas de ${metierName.metierName} disponible pour
                  l'instant. Merci de revenir plus tard.
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaisonFilter;
