import React from "react";
import { useState } from "react";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import annonce from "../../Assets/annonce.jpg";
import { Dialog } from "@mui/material";

const ServicesPage = ({ metiers, profiles }) => {
  const [domaines, setDomaines] = useState([]);
  const conctat = +237698427176;
  const [searchText, setSearchText] = useState(""); // State for search term
  const [filteredMetiers, setFilteredMetiers] = useState(metiers); // Filtered list


  const [isOpen, setIsOpen] = useState(false);


  const Close = () => {
    setIsOpen(false);
  };

  const handleClick = (metier) => {
    console.log(metier)
    setDomaines(metier);
    setIsOpen(true);
  };

  const handleSearchChange = (event) => {
    const newSearchText = event.target.value.toLowerCase(); // Lowercase for case-insensitive search
    setSearchText(newSearchText);

    const filteredList = metiers.filter((metier) =>
      metier.titre.toLowerCase().includes(newSearchText) // Case-insensitive search
    );
    setFilteredMetiers(filteredList);
  };

  return (
    <div className="mt-8 pt-8">
      <div>
        <Dialog open={isOpen}>
          <div
            role="alert"
            className="rounded-xl border border-gray-100 bg-white p-4"
          >
            <div>
              <img src={annonce} alt="" />
            </div>

            <div className="mt-2 flex justify-center">
              <div className="inline-flex gap-2">
                <Link
                  className="border-2 border-blue-600 px-3 py-2 rounded-lg text-white bg-blue-600 text-center cursor-pointer text-xs font-medium"
                  to={`https://wa.me/${conctat}?text=Bonjour Emploi Monde J'ai besoin des services d'une personne qui fait dans le domaine ${domaines.titre},  Pouvez-vous me fournir plus d'informations sur ses services ?`}
                  target="_blank"
                >
                  Laissez nous faire le choix
                </Link>
                <Link
                  className="border-2 border-blue-600 px-3 py-2 rounded-xl text-white bg-blue-600 text-xs text-center font-medium"
                  to={`/services/${domaines.titre}`}
                >
                  Choisir un de nos techniciens
                </Link>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <button
                className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                onClick={Close}
              >
                Fermer
              </button>
            </div>
          </div>
        </Dialog>
      </div>

      <div className="mt-8 w-full max-w-screen-xl mx-auto">
        <input
          className="w-full p-3 border mx-2 rounded-md"
          type="text"
          placeholder="Rechercher un domaine d'activité"
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>

      <div className="mt-4">
        {filteredMetiers.map((metier, i) => (
          <div className="max-w-screen-xl mx-auto" key={i}>
            <h1
              className="p-2 border flex items-center justify-between cursor-pointer"
              onClick={() => handleClick(metier)}
            >
              {metier.titre} <ChevronRight />{" "}
            </h1>
          </div>
        ))}
      </div>


      {/* <div className="mt-4">
        {metiers.map((metier, i) => (
          <div className="max-w-screen-xl mx-auto" key={i}>
            <h1
              className="p-2 border flex items-center justify-between cursor-pointer"
              onClick={() => handleClick(metier)}
            >
              {metier.titre} <ChevronRight />{" "}
            </h1>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ServicesPage;
