import React from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ScreenSearchDesktopIcon from "@mui/icons-material/ScreenSearchDesktop";
import EngineeringIcon from "@mui/icons-material/Engineering";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Face2Icon from "@mui/icons-material/Face2";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Dialog } from "@mui/material";
import annonce from "../../Assets/annonce.jpg";

const Content = ({ metiers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const conctat = +237698427176;

  const Close = () => {
    setIsOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (metier) => {
    setIsOpen(true);
  };

  return (
    <div className="bg-white-900 mb-8">
      <div>
        <Dialog open={isOpen}>
          <div
            role="alert"
            className="rounded-xl border border-gray-100 bg-white p-4"
          >
            <div>
              <img src={annonce} alt="" />
            </div>

            <div className="mt-2">
              <div className="flex justify-center gap-2">
                <Link
                  className="border-2 border-blue-600 px-3 py-2 rounded-lg text-white bg-blue-600 text-center cursor-pointer text-xs font-medium"
                  to={`https://wa.me/${conctat}?text=Bonjour Emploi Monde J'ai besoin des services d'un mecanicien. Merci`}
                  target="_blank"
                >
                  Laissez nous faire le choix
                </Link>
                <Link
                  className="border-2 border-blue-600 px-3 py-2 rounded-xl text-white bg-blue-600 text-xs text-center font-medium"
                  to="/mecanicien"
                >
                  Choisir un de nos Mécanicien
                </Link>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <button
                className="px-10 py-2 border bg-red-600 text-white rounded-xl"
                onClick={Close}
              >
                Fermer
              </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="relative px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="relative grid gap-3 grid-cols-2 md:grid-cols-3">
          <div>
            <Link to="offre">
              <div className="px-6 py-6 text-center transition duration-300 h-full transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl justify-center">
                <ScreenSearchDesktopIcon className="text-white text-4xl" />
                <p className="font-semibold text-gray-200 text-sm">
                  S'enregistrer
                </p>
              </div>
            </Link>
          </div>

          <div>
            <Link to="employers">
              <div className="px-6 py-6 text-center transition duration-300 h-full transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl ">
                <PersonSearchIcon className="text-white text-4xl font-bold" />
                <p className="font-semibold text-gray-200 text-sm">
                  Besoin d'employers
                </p>
              </div>
            </Link>
          </div>

          <div>
            <Link to="services">
              <div className="px-6 py-6 text-center transition duration-300 h-full transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl ">
                <EngineeringIcon className="text-white text-4xl font-bold" />
                <p className="font-semibold text-gray-200 text-sm">
                  Besoin d'un Service
                </p>
              </div>
            </Link>
          </div>

          <div>
            <Link to="employers-maison">
              <div className="px-2 py-6 text-center transition duration-300 h-full transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl ">
                <CleaningServicesIcon className="text-white text-4xl font-bold" />
                <p className="font-semibold text-gray-200 text-sm">
                  Employers de maison
                </p>
              </div>
            </Link>
          </div>
          <div>
            <Link to="coiffure">
              <div className="px-6 h-full py-6 text-center transition duration-300 transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl ">
                <Face2Icon className="text-white text-4xl font-bold" />
                <p className="font-semibold text-gray-200 text-sm">Coiffure</p>
              </div>
            </Link>
          </div>
          <div>
            <Link to="travel">
              <div className="px-6 py-6 text-center h-full transition duration-300 transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl ">
                <DirectionsBusIcon className="text-white text-4xl font-bold" />
                <p className="font-semibold text-gray-200 text-sm">
                  Agences de voyage
                </p>
              </div>
            </Link>
          </div>
          <div>
            <Link onClick={handleClick}>
              <div className="px-10 py-6 text-center h-full transition duration-300 transform bg-blue-700 rounded-xl shadow-2xl hover:scale-105 md:shadow-xl hover:shadow-2xl ">
                <DirectionsBusIcon className="text-white text-4xl font-bold" />
                <p className="font-semibold text-gray-200 text-sm">
                  Mécanicien{" "}
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
