import React from 'react'
import Category from './Category/Category'

const AdmiHome = () => {
  return (
    <div>
      <Category/>
    </div>
  )
}

export default AdmiHome
