import React from "react";
import { useState } from "react";
import { TextField, Grid, Autocomplete } from "@mui/material";
import { useEffect } from "react";

const AdminTechnicien = ({ metiers, profiles }) => {
    const [candidats, setCandidats] = useState([]);
  const [categorieSelectionnee, setCategorieSelectionnee] = useState("tous");
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event, value) => {
    setSelectedValue(value);
    setCategorieSelectionnee(value?.titre);
  };

  const options = metiers?.map((option, i) => {
    const firstLetter = option?.titre[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const goToDetails = (id) => {
    console.log(id)
  }

  useEffect(() => {
    if ((categorieSelectionnee === "tous" || categorieSelectionnee === undefined)) {
      setCandidats(profiles.filter((profile) => (profile.type === "technicien" || profile.type === "prestataire")));
    } else {
      setCandidats(
        profiles.filter(
          (profile) =>
            profile.type === "technicien" &&
            profile.profil.metier.titre === categorieSelectionnee
        )
      );
    }
  }, [profiles, categorieSelectionnee]);
  return (
    <div className="px-4 py-16 mt-8 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="mx-auto mb-10 lg:max-w-xl sm:text-center">
        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
         Liste des Techniciens present sur la plate forme
        </p>
        <Grid item xs={12}>
          <Autocomplete
            id="metier"
            isOptionEqualToValue={(option, value) => option.id === value.id} // Comparez uniquement les IDs
            options={options.sort(
              (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
            )}
            getOptionLabel={(option) => option.titre}
            name="metier"
            onChange={handleChange} // Utilisez l'argument 'value' directement
            renderInput={(params) => (
              <TextField {...params} label="Profil recherché" />
            )}
          />
        </Grid>
      </div>

        <div className="grid gap-10 mx-auto lg:max-w-screen-lg grid-cols-2 sm:grid-cols-3 lg:grid-cols-4">
      {candidats.map((candidat) => (
          <div className="flex flex-col items-center cursor-pointer" key={candidat.id} onClick={() => goToDetails(candidat.id)}>
            <img
              className="object-cover w-20 h-20 mb-2 rounded-full shadow"
              src={candidat.images[0]}
              alt="Person"
            />
            <div className="flex flex-col items-center">
              <p className="text-lg font-bold">{candidat.firstName} {""} {candidat.lastName} </p>
              <p className="text-sm text-gray-800">{candidat?.profil?.metier?.titre}</p>
            </div>
          </div>
        ))}
        </div>
    </div>
  )
}

export default AdminTechnicien
