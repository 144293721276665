import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../../utils/constant_api";
import { Alert, Stack } from "@mui/material";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Dialog } from "@mui/material";
import { FaDeleteLeft } from "react-icons/fa6";
import { FaTrash } from "react-icons/fa";


const CoiffureForm = () => {
  const [loading, setLoading] = useState(false)
  const [errorMessage, setError] = useState("");

  const [nom, setNom] = useState("");
  const [prix, setPrix] = useState("");
  const [lieux, setLieux] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");

  const [coiffures, setCoiffures] = useState([]);
  const [hairstyle, setHairstyle] = useState([]);
  const conctat = +237698427176;

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (coiffure) => {
    setHairstyle(coiffure)
    setIsOpen(true);
  };

  const Close = () => {
    setIsOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Aperçu de l'image
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    const formData = new FormData();
    formData.append("nom", nom);
    formData.append("prix", prix);
    formData.append("lieux", lieux);
    formData.append("description", description);
    formData.append("image", image);

    try {
      await axios.post(`${baseUrl}/coiffures/new`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res)
        if(res.status === 200 || res.status === 201) {
          window.location.href = "/admin";
        }
      })
    } catch (error) {
      setError(error.response?.data?.errors);
      setLoading(false);
    }
  };

  const deleteCoiffure = () => {
    console.log('suppression de la coiffure')
    Close();
  }

  useEffect(() => {
    const getCoiffures = async () => {
      axios.get(`${baseUrl}/coiffures`).then((res) => {
        setCoiffures(res.data);
      });
    };
    getCoiffures();
  }, []);


  return (
    <div className="pt-8 ">
      <h1 className="text-lg font-medium mt-4">Créer un model de coiffure </h1>   
      <form
        onSubmit={handleSubmit}
        className="border-1 shadow-xl flex flex-col p-4 lg:p-4 my-8  max-w-xl w-full items-start gap-2 mx-auto"
      >
        {errorMessage !== "" ? (
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Alert severity="error">{errorMessage}</Alert>
          </Stack>
        ) : (
          <span></span>
        )}
        <div className="w-full border rounded-lg ">
          <input
            className="w-full p-2"
            type="text"
            name="nom"
            placeholder="Nom de la coiffure"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          />
        </div>
        <div className="w-full border rounded-lg ">
          <input
            className="w-full p-2"
            type="number"
            name="prix"
            placeholder="Prix de la coiffure"
            value={prix}
            onChange={(e) => setPrix(e.target.value)}
          />
        </div>
        <div className="w-full border rounded-lg ">
          <input
            className="w-full p-2"
            type="text"
            name="lieux"
            placeholder="Lieux où la coiffure est disponible"
            value={lieux}
            onChange={(e) => setLieux(e.target.value)}
          />
        </div>
        <div className="w-full border rounded-lg ">
          <textarea
            className="w-full p-2"
            name="description"
            placeholder="Description de la coiffure"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="w-full border rounded-lg ">
          <input
            className="w-full p-2"
            type="file"
            name="image"
            accept="image/*"
            onChange={handleImageChange}
          />

          {preview && <img src={preview} alt="Aperçu de la coiffure" className="max-h-32 object-cover"/>}
        </div>

        {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
        <button
          type="submit"
          className="border rounded-lg bg-blue-700 text-white px-4 py-2 mx-auto"
        >
          Créer la coiffure
        </button>
        )}
      </form>
      <div className="mt-8">
      <section className="py-10 bg-gray-100">
        <div>
          <Dialog open={isOpen}>
            <div
              role="alert"
              className="rounded-xl border border-gray-100 bg-white p-4"
            >
              <div>
                <h1>voulez vous vraiment supprimer cette coiffures ? </h1>
              </div>
              <div className="mt-4">
                <div className="flex justify-center gap-2">
                  <h1
                    className="border-2 border-blue-600 px-4 py-2 rounded-xl text-blue-600"
                    onClick={Close}
                  >
                    Annuler
                  </h1>

                  <h1
                    className="border-2 border-red-600 px-4 py-1 rounded-lg text-red-600 cursor-pointer"
                    onClick={deleteCoiffure}
                   
                  >
                    Supprimer
                  </h1>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                  onClick={Close}
                >
                  Fermer
                </button>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {coiffures.map((coiffure, i) => (
            <div
              className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300"
              key={i}
            >
              <div className="flex justify-center coiffure-img overflow-hidden rounded-xl">
                <img src={coiffure?.image} alt="Hotel" />
              </div>

              <div className="mt-1 p-2">
                <h2 className="text-slate-700">{coiffure.nom}</h2>
                <p className="mt-1 text-sm text-slate-400">{coiffure.lieux}</p>
                <p className="mt-1 text-sm text-slate-400">
                  {coiffure.description}
                </p>
                <p className="my-2 text-sm text-slate-600">
                  Optenez plus de details sur la coiffure en effectuant la
                  commande
                </p>

                <div className="mt-3 flex items-end justify-between gap-2">
                  <p className="text-lg font-bold text-blue-700">
                    {coiffure.prix.split(".")[0]} Xaf
                  </p>

                  <div className="flex items-center space-x-1 rounded-lg bg-red-700 px-2 py-1 text-white duration-100 hover:bg-blue-600">
                    <FaTrash/>

                    <h1
                      className="text-sm text-white cursor-pointer"
                      onClick={() =>handleClick(coiffure)}
                    >
                     Supprimer
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
    </div>
  );
};

export default CoiffureForm;
