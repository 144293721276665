import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../Assets/emploi-monde.png";
import { IoIosHome } from "react-icons/io";
import { FaProjectDiagram } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { ImMenu } from "react-icons/im";
import { IoCloseCircle } from "react-icons/io5";

const AdminSideBAr = (authUser) => {
  const [color, setColor] = useState("");
  const [isMobile, setIsmobile] = useState(false);

  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const stateChange = (e) => {
    e.preventDefault();
    setIsmobile((prev) => !prev);
  };

  useEffect(() => {
    const val =
      "#" + Math.floor(Math.random() * (256 * 256 * 256)).toString(16);
    setColor(val);
  }, []);

  const navigation = [
    {
      to: "/admin",
      name: "Accueil",
      icon: <IoIosHome color="blue" size={25} />,
    },
    {
      to: "metiers-list",
      name: "Metiers",
      icon: <FaProjectDiagram color="blue" size={25} />,
    },
    {
      to: "chat",
      name: "Message",
      icon: <IoMail color="blue" size={25} />,
    },
  ];

  const navsFooter = [
    {
      to: "",
      name: "Help",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
          />
        </svg>
      ),
    },
    {
      to: "",
      name: "Settings",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      ),
    },
    {
      name: "Deconnexion",
      onclick: logout,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
          />
        </svg>
      ),
    },
  ];
  return (
    <div className="">
      <div className="absolute mx-3 my-2 lg:hidden flex items-center justify-between w-full">
        <Link to="/" className="flex-none">
          <img src={logo} width={70} className="mx-auto" alt="logo"/>
        </Link>
        <div className="mx-5">
          <ImMenu size={30} onClick={stateChange} className="cursor-pointer" />
        </div>
      </div>
      {isMobile && (
        <div className="">
          <div className="absolute mx-3 my-2 lg:hidden flex items-center justify-between w-full">
            <Link to="/" className="">
              <img src={logo} width={70} className="mx-auto" alt="logo"/>
            </Link>
          </div>

          <nav className="lg:hidden fixed top-0 right-0 w-full h-screen border-r bg-black space-y-8 sm:w-80 mx-3 z-50">
            <div className="absolute right-4 top-2">
              <IoCloseCircle
                size={40}
                onClick={stateChange}
                className="bg-white text-red-500 rounded-full w-full mx-2"
              />
            </div>
            <div className="flex flex-col h-full">
              <div className="h-20 flex items-center px-8 my-4">
                <Link to="/" className="flex-none">
                  <img src={logo} width={140} className="mx-auto" alt="logo"/>
                </Link>
              </div>
              <div className="flex-1 flex flex-col h-full overflow-auto">
                <ul className="px-4 text-sm font-medium flex-1">
                  {navigation.map((item, idx) => (
                    <li key={idx} onClick={stateChange}>
                      <Link
                        to={item.to}
                        className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150 text-lg"
                      >
                        <div className="text-gray-500 mx-2">{item.icon}</div>
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
                <div>
                  <ul className="px-4 pb-4 text-sm font-medium">
                    {navsFooter.map((item, idx) => (
                      <li key={idx} onClick={item.onclick}>
                        <Link
                          to={item.to}
                          className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150"
                        >
                          <div className="text-gray-500">{item.icon}</div>
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="py-4 px-4 border-t">
                    <div className="flex items-center gap-x-4">
                      <div
                        className="w-12 h-12 rounded-full flex justify-center items-center "
                        style={{ background: `${color}` }}
                      >
                        <span className="flex justify-center items-center font-medium text-2xl text-white">
                          v
                        </span>
                      </div>
                      {/* <img src="https://randomuser.me/api/portraits/women/79.jpg" className="w-12 h-12 rounded-full" /> */}
                      <div>
                        <span className="block text-white text-lg font-medium">
                          {authUser?.name}
                        </span>
                        <Link
                          to=""
                          className="block mt-px text-gray-600 hover:text-indigo-600 text-xs"
                        >
                          {authUser?.email}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      )}
      <nav className="hidden lg:flex fixed top-0 left-0 w-full h-screen border-r bg-white space-y-8 sm:w-80 shadow-2xl">
        <div className="flex flex-col h-full">
          <div className="h-20 flex items-center px-8 my-4">
            <Link to="/" className="flex-none">
              <img src={logo} width={100} className="mx-auto" alt="logo"/>
            </Link>
          </div>
          <div className="flex-1 flex flex-col h-full overflow-auto">
            <ul className="px-4 text-sm font-medium flex-1">
              {navigation.map((item, idx) => (
                <li key={idx}>
                  <Link
                    to={item.to}
                    className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150 text-lg"
                  >
                    <div className="text-gray-500 mx-2">{item.icon}</div>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
            <div>
              <ul className="px-4 pb-4 text-sm font-medium">
                {navsFooter.map((item, idx) => (
                  <li key={idx} onClick={item.onclick}>
                    <Link
                      to={item.to}
                      className="flex items-center gap-x-2 text-gray-600 p-2 rounded-lg  hover:bg-gray-50 active:bg-gray-100 duration-150"
                    >
                      <div className="text-gray-500">{item.icon}</div>
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
              <div className="py-4 px-4 border-t">
                <div className="flex items-center gap-x-4">
                  <div
                    className="w-12 h-12 rounded-full flex justify-center items-center "
                    style={{ background: `${color}` }}
                  >
                    <span className="flex justify-center items-center font-medium text-2xl text-white">
                      G
                    </span>
                  </div>
                  <div>
                    <span className="block text-lg font-medium">
                      Gemael Fokam
                    </span>
                    <Link
                      to=""
                      className="block mt-px text-gray-600 hover:text-indigo-600 text-xs"
                    >
                      {authUser?.email}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default AdminSideBAr;
