import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Alert,
  Stack,
  CircularProgress,
  TextField,
  Autocomplete,
} from "@mui/material";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { baseUrl } from "../utils/constant_api";
import axios from "axios";
import { useRef } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const UpdateProfile = ({ authUser, metiers }) => {
  console.log(authUser)
  const [loading, setLoading] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  // const [password, setPassword] = useState("");
  const [errorMessage, setError] = useState("");

  const [firstName, setFirstName] = useState();
  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [lastName, setLastName] = useState("");
  const [num_finance_mtn, setnum_finance_mtn] = useState("");
  const [num_finance_orange, setnum_finance_orange] = useState("");
  const pays = "Cameroun";
  const [ville, setVille] = useState("");
  const [biographie, setBiographie] = useState("");
  const [quatier, setQuatier] = useState("");

  const [image, setImage] = useState();
  const [loader, setLoader] = useState(true);

  const [images, setImages] = useState([]);
  const [myFiles, setMyFiles] = useState([]);
  const fileInputRef = useRef(null);

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedValue_2, setSelectedValue_2] = useState(null);
  const [selectedValue_3, setSelectedValue_3] = useState(null);
  const options = metiers?.map((option, i) => {
    const firstLetter = option?.titre[0]?.toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type === "application/pdf") {
      setFile(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please select a valid PDF file.");
    }
  };

  const onFileSelect = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    const files = Array.from(event.target.files);
    setMyFiles(files);

    if (files.length === 0) return;

    const imageFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
      imageFiles.push(files[i]);
    }
    setMyFiles(imageFiles);
    console.log(images);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    const metier_id = selectedValue?.id;
    const metier_id_2 = selectedValue_2?.id;
    const metier_id_3 = selectedValue_3?.id;

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("pays", pays);
    formData.append("phoneNumber", phoneNumber);
    formData.append("email", email);
    formData.append("quatier", quatier);
    formData.append("metier_id", metier_id);
    formData.append("metier_id_2", metier_id_2);
    formData.append("metier_id_3", metier_id_3);
    formData.append("biographie", biographie);
    formData.append("num_finance_mtn", num_finance_mtn);
    formData.append("num_finance_orange", num_finance_orange);
    myFiles.forEach((file) => formData.append("images[]", file));

    axios
      .post(`${baseUrl}/user/profile/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status === 201 || res.status === 200) {
          window.location.href = "/profil-page";
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.errors);
        setLoading(false);
      });
  };

  useEffect(() => {
    const loadData = async () => {
      setFirstName(authUser?.firstName);
      setPhoneNumber(authUser?.phoneNumber);
      setLastName(authUser?.lastName);
      setnum_finance_mtn(authUser?.profil.num_finance_mtn);
      setnum_finance_orange(authUser?.profil.num_finance_orange);
      setEmail(authUser?.email);
      setImages(authUser?.images[0]);
      setVille(authUser?.ville)
      setQuatier(authUser?.profil?.quatier)
      setBiographie(authUser?.profil?.biographie);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setLoader(false);
    };
    loadData();
  }, [authUser, ]);

  return (
    <div>
      {loader ? (
        <div className="loader">
          Chargement...
          <CircularProgress />
        </div> // Votre loader ici
      ) : (
        <div className="pt-10 mt-8 pb-10">
          <div>
            <h1 className="font-medium text-xl text-blue-600 uppercase mt-4">
              mise a jour du profil
            </h1>

            {errorMessage !== "" ? (
              <Stack sx={{ width: "100%" }} spacing={1}>
                <Alert severity="error">{errorMessage}</Alert>
              </Stack>
            ) : (
              <span></span>
            )}
          </div>

          <form
            onSubmit={handleSubmit}
            className="p-4 border-1 rounded-md max-w-xl md:mx-auto my-4"
          >
            <div className=" border-2 border-blue-600 border-dashed p-2 w-full mt-4">
              <div className="new-bottomLeft">
                <img src={authUser?.images} alt="" />
              </div>
              <div className="newFormInput justify-center flex">
                <label
                  htmlFor="file"
                  className="rounded-xl border-2 border-blue-600  px-4 py-1 my-2"
                >
                  Ajoutez votre photo de profil ou votre logo{" "}
                  <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  ref={fileInputRef}
                  onChange={onFileSelect}
                  style={{ display: "none" }}
                />
              </div>
            </div>

            <div className="flex gap-1">
              <div className="w-full mt-2">
                <label htmlFor="" className="w-full text-start">
                  {authUser?.type === "agence" ? "Nom de l'agence" : "Nom"}
                </label>
                <input
                  type="text"
                  className="w-full border p-2 rounded-md"
                  placeholder={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
              <div className="w-full mt-2">
                <label htmlFor="" className="w-full text-start">
                  {" "}
                  {authUser?.type === "agence" ? "Agence de :" : "Prénom"}
                </label>
                <input
                  type="text"
                  className="w-full border p-2 rounded-md"
                  placeholder={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
            </div>

            <div className="w-full mt-2 lg:flex gap-2">
              <div className="w-full">
                <select
                  name="HeadlineAct"
                  id="HeadlineAct"
                  className=" w-full rounded-lg border-2 text-gray-700 sm:text-sm p-3 mb-2"
                  onChange={(e) => setVille(e.target.value)}
                  value={ville}
                >
                  <option value="">Ville </option>
                  <option value="Bafoussam">Bafoussam</option>
                  <option value="Bamenda">Bamenda</option>
                  <option value="Douala">Douala</option>
                  <option value="Edéa">Edéa</option>
                  <option value="Yaoundé">Yaoundé</option>
                </select>
              </div>
              <TextField
                className="mb-2 w-full"
                label="Quatier"
                value={quatier}
                onChange={(e) => setQuatier(e.target.value)}
                required
              />
            </div>

            <div className="w-full mt-2">
              <label htmlFor="" className="w-full text-start">
                Numéro de télephone:
              </label>
              <input
                type="text"
                className="w-full border p-2 rounded-md"
                placeholder={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="mb-2 mt-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  value={selectedValue}
                  onChange={(event, value) => setSelectedValue(value)} // Utilisez l'argument 'value' directement
                  renderInput={(params) => (
                    <TextField {...params} label="Domaine d'activité" />
                  )}
                />
              </Grid>
            </div>
            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  onChange={(event, value) => setSelectedValue_2(value)}
                  value={selectedValue_2}
                  renderInput={(params) => (
                    <TextField {...params} label="Autre compétence 1" />
                  )}
                />
              </Grid>
            </div>
            <div className="mb-2">
              <Grid item xs={12}>
                <Autocomplete
                  id="metier"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  } // Comparez uniquement les IDs
                  options={options.sort(
                    (a, b) => -b.firstLetter?.localeCompare(a.firstLetter)
                  )}
                  getOptionLabel={(option) => option.titre}
                  name="metier"
                  onChange={(event, value) => setSelectedValue_3(value)}
                  value={selectedValue_3}
                  renderInput={(params) => (
                    <TextField {...params} label="Autre compétence 2" />
                  )}
                />
              </Grid>
            </div>

            <div>
              <TextField
                type="texte"
                onChange={(e) => setBiographie(e.target.value)}
                value={biographie}
                label="Faites une description de vous"
                multiline
                rows={6}
                fullWidth
              />
            </div>

            {authUser?.type === "agence" && (
              <div className="w-full gap-2">
                <div className="w-full mt-2">
                  <label htmlFor="" className="w-full text-start">
                    Numéro MTN Momo
                  </label>
                  <input
                    type="text"
                    className="w-full border p-2 rounded-md"
                    placeholder={num_finance_mtn}
                    onChange={(e) => setnum_finance_mtn(e.target.value)}
                  />
                </div>
                <div className="w-full mt-2">
                  <label htmlFor="" className="w-full text-start">
                    Numéro Orange Money
                  </label>
                  <input
                    type="text"
                    className="w-full border p-2 rounded-md"
                    placeholder={num_finance_orange}
                    onChange={(e) => setnum_finance_orange(e.target.value)}
                  />
                </div>
              </div>
            )}

            {authUser?.type === "employe" && (
              <div className="mt-2">
                <h1 className="font-medium mb-1 text-lg">Ajoutez un CV</h1>
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={handleFileChange}
                  style={{ marginBottom: "20px" }}
                />

                {previewUrl && (
                  <div style={{ marginBottom: "20px", height: "500px" }}>
                    <Worker
                      workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                    >
                      <Viewer fileUrl={previewUrl} />
                    </Worker>
                  </div>
                )}
              </div>
            )}

            <Grid item xs={12} sm={6}>
              <div className="w-full mt-2">
                <label htmlFor="" className="w-full text-start">
                  Adresse email:
                </label>
                <input
                  type="text"
                  className="w-full border p-2 rounded-md"
                  placeholder={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Grid>
            {loading ? (
              <i className="fa fa-spinner mt-2 text-danger fa-spin fa-2x"></i>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Mettre à jour
              </Button>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default UpdateProfile;
