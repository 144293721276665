import React, { useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/constant_api";
import { Alert, Stack } from '@mui/material';


const ConfirmMail = () => {


  const [errorMessage, setError] = useState("");
  const [verificationCode, setCode] = useState({ verificationCode: "", email: localStorage.getItem("email")})

  const handleChange = (e) => {
    setCode({ ...verificationCode, [e.target.name]: e.target.value });
  };

  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)

    axios
      .post(`${baseUrl}/authentication/verification`, verificationCode)
      .then((res) => {
        setLoading(false);
        if (res.data.status === "ok") {
          localStorage.setItem("token", res.data.token);
          window.location.href = "/client/monespace";
          // navigate("/client/monespace")
        } else {
          Object.keys(res.data.errors).forEach(key => {
            if (res.data.errors[key].length > 0) {
                setError(res.data.errors[key][0]);
                setLoading(false)
            }
        })
        }
      })
      .catch((error) => {
        setError(error.response.data.message)
        setLoading(false)
      });
  };

  const resetEmail = async (e) => {
    e.preventDefault();
    setLoading(true)
    const email = localStorage.getItem("email")
    axios
      .post(`${baseUrl}/auth/code/resend`, { email: email })
      .then((res) => {

        setLoading(false)
        if (res.data.status === "ok") {
          // setSuccess(true);
          setError(res.data.message);
        } else {
          setError(res.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });
  };


  return (
    <div className="login paddinds">
      <div className="L-wrapper paddings">
        <form action="" className="form" onSubmit={handleSubmit}>
          <div className="right">
            <h3 className="primaryText">Confirmation d'adresse mail { localStorage.getItem("email")}</h3>

            {errorMessage !== "" ? (
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="error">{errorMessage}</Alert>
              </Stack>
            ) : (
              <span></span>
            )}
            <div>
              <input
                type="numbers"
                className="infoInput mb-0"
                name="verificationCode"
                value={verificationCode.verificationCode}
                placeholder="Entrez le code de confirmation"
                onChange={handleChange}
              />
            </div>  {loading ? (
              <i className="fa fa-spinner fa-spin fa-2x"></i>
            ) : (
              <button className="button submit">Confirmer</button>)}
            <span className="message" onClick={resetEmail}>
              Envoyer a nouveau 
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConfirmMail;
