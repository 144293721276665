export const voyagesData = [
    {
        "ville_depart": "Yaoundé",
        "ville_arrivee": "Douala",
        "date_voyage": "2024-02-28",
        "places_disponibles": 50,
        "heure_voyage": "08:00",
        "id": 1,
    }
      ,
      {
        "id": 2,
        "ville_depart": "Douala",
        "ville_arrivee": "Yaoundé",
        "date_voyage": "2024-02-28",
        "places_disponibles": 40,
        "heure_voyage": "10:30"
      }
      ,
      {
        "id": 3,
        "ville_depart": "Douala",
        "ville_arrivee": "Yaoundé",
        "date_voyage": "2024-02-28",
        "places_disponibles": 30,
        "heure_voyage": "13:45"
      },
      {
        "id": 4,
        "ville_depart": "Douala",
        "ville_arrivee": "Yaoundé",
        "date_voyage": "2024-02-28",
        "places_disponibles": 40,
        "heure_voyage": "10:30"
      },
    ]

    export const Destinations = [
       {
        "id": 1,
        "name": "Douala", 
       }, 
       {
        "id": 2,
        "name": "Yaoundé"
       }, 
       {
        "id": 3,
        "name": "Bafoussam"
       }
    ]

