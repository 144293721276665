import React from 'react'

const Hero2 = () => {
  return (
    <div className="bg-blue-800 lg:hidden mt-16">
      <div className="px-4 py-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
          <div className="flex flex-col sm:text-center sm:mb-0">
            <div className="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <h2 className="max-w-lg mb-4 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
      
              Bienvenue Chez Emploi Monde
              </h2>
              <p className="text-base text-indigo-100 md:text-lg">
              Découvrez un monde de possibilités professionnelles et de voyages passionnants grâce à notre plateforme tout-en-un.
              </p>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero2