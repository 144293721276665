import axios from "axios";
import React from "react";
import { useState } from "react";
import { baseUrl } from "../utils/constant_api";
import { Password } from "@mui/icons-material";

const ResetPassword = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [step, setStep] = useState(1); // Étape 1: Entrée du numéro de téléphone
  const [phone, setPhone] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSendCode = () => {
    if (!phone) {
      setError("Veuillez entrer un numéro de téléphone.");
      return;
    }

    setLoading(true);
    axios
      .post(`${baseUrl}/send-otp`, { phoneNumber: phone })
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (res.status === 201) {
          setStep(2);
          setError("");
        } else {
          console.log(res);
          setError(res.data.message);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setLoading(false);
      });

    // Appeler une API pour envoyer le code de réinitialisation
    console.log(`Envoyer le code à: ${phone}`);
    setStep(2);
    setError("");
  };

  const handleVerifyCode = () => {
    setLoading(true);
    axios
      .post(`${baseUrl}/verify-otp`, { phoneNumber: phone, otp: otp })
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setLoading(false);
          setError("");
          setStep(3);
        } else {
          setError(res.data.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.message);
        setLoading(false);
      });
    // if (code === "1234") {
    //   // Simuler la vérification du code
    //   setStep(3);
    //   setError("");
    // } else {
    //   setError("Code invalide.");
    // }
  };

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    axios
      .post(`${baseUrl}/reset/password`, {
        new_password: newPassword,
        phoneNumber: phone,
      })
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          window.location.href = "/login";
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setError(
          error?.response?.data?.errors ||
            "une Erreur est survenu veuillez réessayer plus tard"
        );
      });

    // Appeler une API pour mettre à jour le mot de passe
    console.log("Nouveau mot de passe:", newPassword);
    setError("");
  };

  return (
    <div className="my-20 lg py-8 rounded-md max-w-xl md:mx-auto lg:mx-auto border-2 ">
      {step === 1 && (
        <div>
          <div className="w-full p-4">
            <h3 className="font-semibold text-xl text-center ">
              Réinitialiser le mot de passe
            </h3>
            <h3 className="text-center text-gray-600 font-medium mb-2">
              Entrez votre numéro de téléphone pour recevoir un code de
              réinitialisation :
            </h3>
            <div className="w-full mt-3 mb-2">
              <input
                type="number"
                className="w-full p-2 border rounded-md bg-gray-100"
                name="phone"
                value={phone}
                placeholder="Numéro de téléphone"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>{" "}
            {loading ? (
              <i className="fa fa-spinner fa-spin fa-2x"></i>
            ) : (
              <button
                className="w-full bg-blue-700 rounded-md text-white p-2"
                onClick={handleSendCode}
              >
                Envoyer le code
              </button>
            )}
            <div className="mt-4 w-full flex justify-center">
              <span
                className="text-center text-blue-600 cursor-pointer border-1 py-1.5 px-3 rounded-md border-blue-600"
                // onClick={handleSendCode}
              >
                Envoyer a nouveau
              </span>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="p-4 ">
          <h2 className="font-semibold text-xl text-center ">
            Confirmer le code
          </h2>
          <p className="text-center text-gray-600 font-medium mb-2">
            Entrez le code que vous avez reçu :
          </p>
          <div className="w-full mt-3 mb-2">
            <input
              type="text"
              placeholder="Code de vérification"
              className="w-full p-2 border rounded-md bg-gray-100 mx-auto overflow-hidden"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button
              onClick={handleVerifyCode}
              className="w-full bg-blue-700 rounded-md text-white p-2"
            >
              Vérifier
            </button>
          )}
        </div>
      )}

      {step === 3 && (
        <div className="p-2">
          <h2 className="font-semibold text-xl text-center ">
            Réinitialiser le mot de passe
          </h2>
          <input
            type="password"
            className="w-full mb-2 p-2 border rounded-md bg-gray-100 mx-auto overflow-hidden"
            placeholder="Nouveau mot de passe"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            type="password"
            className="w-full p-2 border rounded-md bg-gray-100 mx-auto overflow-hidden"
            placeholder="Confirmer le mot de passe"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {loading ? (
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          ) : (
            <button
              onClick={handleResetPassword}
              className="w-full bg-blue-700 rounded-md text-white p-2 mt-4"
            >
              Réinitialiser le mot de passe
            </button>
          )}
        </div>
      )}

      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default ResetPassword;
