import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ClientTravelDetails = ({ authUser }) => {
  const { id } = useParams();
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // console.log(reservations)

  // const handleSearchChange = (event) => {
  //   const newSearchText = event.target.value.toString();
  //   setSearchText(newSearchText);

  // const filteredList = reservations.filter((reservation) =>
  //     reservation.numero_reservation?.toString().includes(newSearchText)
  //   );

  //   console.log(filteredList);
  //   setReservations(filteredList);
  //   setIsSearching(!!newSearchText);
  // };

  const [searchQuery, setSearchQuery] = useState("");

  // Fonction pour mettre à jour la requête de recherche
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filtrer les réservations en fonction de la requête de recherche
  const filteredReservations = reservations?.filter((reservation) =>
    reservation.numero_reservation?.toString().includes(searchQuery)
  );
  console.log(filteredReservations)

  useEffect(() => {
    const loadData = async () => {
      const travel = authUser?.voyages?.find(
        (voyage) => voyage.id === Number(id)
      );
      setReservations(travel?.reservations);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsLoading(false);
    };
    loadData();
  }, [id, authUser, reservations]);

  return (
    <div className="py-24 ">
      <div className="w-full max-w-xl mx-auto">
        <input
          className="w-full p-3 border mx-2 rounded-md"
          type="text"
          placeholder="Rechercher Numéro de reservation"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      {isLoading ? (
        <div className="loader">
          <CircularProgress size={70} />
        </div>
      ) : (
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                id
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                nom du passagers{" "}
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Numéro CNI
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                N° Reservation
              </th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                statut
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {filteredReservations
              ?.sort((a, b) => b.id - a.id)
              .map((reservation) => (
                <tr key={reservation.id}>
                  <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                    {reservation.id}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                    {reservation.passagers.map((passager, id) => (
                      <li key={id} className="list-none text-left mb-2">
                        {passager.nom + " " + passager.prenom}
                      </li>
                    ))}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                  {reservation.passagers.map((passager, id) => (
                      <li key={id} className="list-none mb-2">
                        {passager.num_cni_id}
                      </li>
                    ))}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                    {reservation.numero_reservation}
                  </td>
                  <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                    <h1 className="border-1 border-blue-600 px-4 py-1 text-blue-600 cursor-pointer">
                      {reservation.status}
                    </h1>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ClientTravelDetails;
