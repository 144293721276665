export const metiers = [
  {
    titre: "Ménagère",
    description: "Ménagère",
  },
  {
    titre: "Nounou",
    description: "Nounou",
  },
  {
    titre: "Répétiteur à domicile",
    description: "Répétiteur à domicile",
  },
  {
    titre: "Chauffeur",
    description: "Chauffeur",
  },
  {
    titre: "Ménagère Résidante",
    description: "Ménagère Résidante",
  },
  {
    titre: "Jardinier",
    description: "Jardinier",
  },
  {
    titre: "Gardien",
    description: "Gardien",
  },
  {
    titre: "Cuisinier",
    description: "Cuisinier",
  },
];
