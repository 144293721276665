import React from "react";
import { Dialog } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import annonce from "../Assets/annonce.jpg";
import { metiers } from "../utils/employerMaison";

const EmployersMaison = ({ profiles }) => {
  const [domaines, setDomaines] = useState([]);
  const conctat = +237698427176;

  const [isOpen, setIsOpen] = useState(false);

  const Close = () => {
    setIsOpen(false);
  };

  const handleClick = (metier) => {
    setDomaines(metier);
    setIsOpen(true);
  };
  return (
    <div className="mt-8 pt-8">
      <div>
        <Dialog open={isOpen}>
          <div
            role="alert"
            className="rounded-xl border border-gray-100 bg-white p-4"
          >
            <div>
              <img src={annonce} alt="" />
            </div>

            <div className="mt-2 flex justify-center">
              <div className="inline-flex gap-2">
                <Link
                  className="border-2 border-blue-600 px-3 py-2 rounded-xl text-white bg-blue-600 text-xs text-center font-medium"
                  to={`/employer-maison/${domaines.titre}`}
                >
                  Choisir un(e) de nos {domaines.titre}
                </Link>

                <Link
                  className="border-2 border-blue-600 px-3 py-2 rounded-xl text-white bg-blue-600 text-xs text-center font-medium"
                  to={`https://wa.me/${conctat}?text=Bonjour Emploi Monde J'ai besoin des services d'une personne qui fait dans le domaine ${domaines.titre}. Merci`}
                  target="_blank"
                >
                  Laissez nous faire le choix
                </Link>
              </div>
            </div>

            <div className="flex justify-center mt-4">
              <button
                className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                onClick={Close}
              >
                Fermer
              </button>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="mt-4">
        {metiers.map((metier, i) => (
          <div className="max-w-screen-xl mx-auto" key={i}>
            <h1
              className="p-2 border flex items-center justify-between cursor-pointer"
              onClick={() => handleClick(metier)}
            >
              {metier.titre} <ChevronRight />{" "}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmployersMaison;
