import React from "react";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { FaSuitcase } from "react-icons/fa6";
import { IoMdHome } from "react-icons/io";
import { IoApps } from "react-icons/io5";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";


const BottomNavigation = () => {
  return (
    <div>
      <div className="w-full z-50">
        <section
          id="bottom-navigation"
          className="lg:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
        >
          {/* <section id="bottom-navigation" className="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"> */}
          <div id="tabs" className="flex justify-between">
            <Link
              to="/"
              className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
            >
              <IoMdHome size={26} className="inline-block mb-1" color="blue"/>
              <span className="tab tab-home block text-xs">Acceuil</span>
            </Link>
            <Link
              to="/services"
              className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
            >
              <FaSuitcase size={25} className="inline-block mb-1" color="blue"/>
              <span className="tab tab-kategori block text-xs">Technicien</span>
            </Link>
            <Link
              to="travel"
              className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
            >
              <DirectionsBusIcon size={25} className="inline-block mb-1" color="blue"/>
              <span className="tab tab-explore block text-xs">
                Voyage
              </span>
            </Link>
            <Link
              to="/services"
              className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
            >
              <IoApps size={25} className="inline-block mb-1" color="blue"/>
              <span className="tab tab-whishlist block text-xs">Services</span>
            </Link>
            <Link
              to="/profil-page"
              className="w-full focus:text-teal-500 hover:text-teal-500 justify-center inline-block text-center pt-2 pb-1"
            >
              <FaUserCircle size={25} className="inline-block mb-1" color="blue"/>
              <span className="tab tab-account block text-xs">Mon profil</span>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
};

export default BottomNavigation;
