import React, { useRef, useState } from "react";
import CoiffureForm from "./CoiffureForm";

const AdminCoiffurePage = (coiffures) => {
  console.log(coiffures);

  const goToCreateCoiffure = () => {
    window.location.href = '/create-coiffurre'
  }

  return (
    <div>
        <div className="m-2 p-4">
          <button className="border-1 bg-blue-700 text-white px-4 py-2" onClick={goToCreateCoiffure} >Ajouter une coiffure</button>
        </div>
      <div> 
        <div>
          <table className="min-w-full divide-y-2 divide-gray-200 text-sm p-4 border rounded-xl">
            <thead className="ltr:text-left rtl:text-right">
              <tr>
                <td className="whitespace-nowrap px-4 py-2 font-bold text-lg">
                  Coiffures
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-bold text-lg">
                  Description
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-bold text-lg">
                  Localité
                </td>
                <td className="whitespace-nowrap px-4 py-2 font-bold text-lg">
                  Prix
                </td>
              </tr>
            </thead>

            <tbody className="divide-y divide-gray-200">
              {coiffures.coiffures.map((item, i) => (
                <tr key={i}>
                  <td className="whitespace-nowrap px-4 py-2">{item.nom}</td>
                  <td className="whitespace-nowrap px-4 py-2 ">
                    {item.description}
                  </td>
                  <td className="whitespace-nowrap px-4 py-2">{item.lieux}</td>
                  <td className="whitespace-nowrap px-4 py-2">{item.prix}</td>
                  <td className="whitespace-nowrap px-4 py-2">
                    <a
                      href="#"
                      className="inline-block rounded bg-red-600 px-4 py-2 text-xs font-medium text-white hover:bg-indigo-700"
                    >
                      Supprimer
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminCoiffurePage;
