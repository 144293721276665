import React from 'react'
import CreateVoyageForm from './CreateVoyageForm '

const AdminTravelPage = () => {
  return (
    <div>
      <CreateVoyageForm/>
    </div>
  )
}

export default AdminTravelPage
