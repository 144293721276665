import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { CircularProgress } from "@mui/material";

const ClientTravelPage = ({ authUser }) => {
  const [voyages, setVoyages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (id) => {
    window.location.href = `/agence-travel/` + id;
  };

  useEffect(() => {
    const getTravel = async () => {
      setVoyages(authUser?.voyages);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      setIsLoading(false);
    };

    getTravel();
  }, [authUser]);
  return (
    <div className="pt-16">
      <div className="mt-4">
        <div className="flex my-2 justify-end mx-4">
          <Link
            className="bg-blue-600 text-white px-4 rounded-lg py-1"
            to="/create-travel"
          >
            Créer un nouveau voyage
          </Link>
        </div>
        <div className="flex mx-4 justify-center my-4">
          <h1 className="font-medium text-lg text-gray-600">
            voyages que vous avez créer
          </h1>
        </div>
        {isLoading ? (
          <div className="loader">
            <CircularProgress size={70} />
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
              <thead className="ltr:text-left rtl:text-right">
                <tr>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Date
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Ville de depart
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    ville d'arrivée
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    Heure
                  </th>
                  <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                    action
                  </th>
                </tr>
              </thead>

              <tbody className="divide-y divide-gray-200">
                {voyages
                  ?.sort((a, b) => b.id - a.id)
                  .map((voyage) => (
                    <tr key={voyage.id}>
                      <td className="whitespace-nowrap px-2 py-2 font-medium text-gray-900">
                        {moment(voyage.date_de_depart).format("DD/MM/YYYY")}
                      </td>
                      <td className="whitespace-nowrap px-4 py-2 text-gray-700">
                        {voyage.ville_depart}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                        {voyage.ville_arrive}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                        {voyage.heure_depart.split("", 5)}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-gray-700">
                        {" "}
                        <h1
                          className="border-1 border-blue-600 px-4 py-1 text-blue-600 cursor-pointer"
                          onClick={() => handleClick(voyage.id)}
                        >
                          voir les reservations
                        </h1>{" "}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientTravelPage;
