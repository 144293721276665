import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../utils/constant_api";
import r from "../../Assets/R.jpeg";
import { CircularProgress, Dialog } from "@mui/material";
import { RiCloseCircleFill } from "react-icons/ri";
import { FaCheckCircle, FaClock } from "react-icons/fa";

const PaymentPage = () => {
  const { id } = useParams();
  const [paymentToken, setPaymentToken] = useState("");
  const [paymentNumber, setPaymentNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [echec, setEchec] = useState(false);
  const [message, setMessage] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    // eslint-disable-next-line no-lone-blocks
    {
      echec ? setOpen(false) : (window.location.href = "/profil-page");
    }
  };

  const handleSubmit = async () => {
    setOpen(true);
    setIsLoading(true);

    const token = localStorage.getItem("token");

    const formData = new FormData();
    formData.append("token", paymentToken);
    formData.append("plan", id);
    formData.append("phone", paymentNumber);

    axios
      .post(`${baseUrl}/process/paiement/subscription`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        if (response.status === 201 || response.status === 200) {
          const code = response?.data.ussd_code;
          const reference = response?.data.reference;

          const verifData = new FormData();
          verifData.append("token", paymentToken);
          verifData.append("plan", id);
          verifData.append("reference", reference);

          axios
            .post(`${baseUrl}/paiement/subscription`, verifData, {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              console.log(res);
              if (res.data.status === "pending") {
                setMessage(
                  `Traitement en cours  Veuillez composez le ${code} pour confirmer la transaction.`
                );
                let intervalId = setInterval(async () => {
                  const res = await axios.post(
                    `${baseUrl}/paiement/subscription`,
                    verifData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  if (res.data.status !== "pending") {
                    clearInterval(intervalId);

                    if (res.data.status === "success") {
                      setIsLoading(false);
                      setMessage(res.data.message + "Votre compte est maintenant actif");
                    } else {
                      setIsLoading(false);
                      setEchec(true);
                      setMessage(
                        `Echec de l'operation veuillez réessayer plus tard !`
                      );
                      console.log("Echec de l'opération");
                    }
                  }
                }, 15000);
              } else if (res.data.status === "success") {
                setIsLoading(false);
                setIsComplete(true);
                setMessage("Souscription affectué avec succès.");
              } else {
                setIsLoading(false);
                setEchec(true);
                setMessage(
                  `Echec de l'operation veuillez réessayer plus tard !`
                );
                console.log("Echec de l'opération");
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
              setEchec(true);
              setOpen(true);
              setMessage(error.response.data.errors);
            });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setOpen(true);
        setEchec(true);
        setMessage(
          "Rassurez vous d'avoir entrer un numero de télephone correct et éssayez à nouveau"
        );
      });
  };

  const getPaymentToken = async () => {
    await axios.get(`${baseUrl}/paiement/token`).then((res) => {
      setPaymentToken(res.data.token);
    });
  };
  useEffect(() => {
    getPaymentToken();
  }, []);
  return (
    <div className="py-12 flex justify-center items-center">
      <div className="border runded-lg mx-2 mt-8 p-4 md:mx-auto max-w-xl w-full">
        <div className="flex justify-between items-center my-2">
          <div>
            <label className="font-medium text-sm text-gray-500">
              Coordonées de paiement
            </label>
          </div>

          <div className="flex p-2 items-center gap-2">
            <img src={r} alt="" className="w-24" />
          </div>
        </div>
        <div className=" items-center w-full mt-2">
          <div className="flex justify-start mb-2">
            <label className="text-sm font-medium cursor-pointer">
              Numéro de téléphone du payeur{" "}
            </label>
          </div>
          <input
            onChange={(e) => setPaymentNumber(e.target.value)}
            className="py-2 px-4 border w-full rounded-xl"
            type="number"
            placeholder="Numéro de téléphone du payeur"
            required
          />
        </div>
        <button
          className="border w-full mt-2 p-2 bg-blue-600 text-white"
          onClick={handleSubmit}
        >
          Payer
        </button>
      </div>
      <div>
        <Dialog open={open}>
          <div
            role="alert"
            className="rounded-xl border border-gray-100 bg-white p-4"
          >
            {isComplete ? (
              <div>
                <div className="flex items-center justify-center">
                  <span className="text-green-600">
                    {echec ? (
                      <RiCloseCircleFill size={55} color="red" />
                    ) : (
                      <FaCheckCircle size={55} color="green" />
                    )}
                  </span>
                </div>

                <p className="mt-1 text-sm text-gray-700">{message}</p>
              </div>
            ) : (
              <div className="flex items-start gap-4">
                <span className="text-green-600">
                  <FaClock size={35} color="green" />
                </span>

                <div className="flex-1">
                  <strong className="block font-bold">
                    {" "}
                    {echec ? "Echec de l'operation" : "Paiment Initié"}{" "}
                  </strong>

                  <p className="mt-1 text-sm text-gray-700">{message}</p>
                </div>
              </div>
            )}

            <div className="flex justify-center mt-4">
              {isLoading ? (
                <div className="flex mx-4 items-center">
                  <CircularProgress />
                </div>
              ) : (
                <button
                  className={
                    echec
                      ? "px-10 py-2 border bg-red-600 text-white rounded-xl"
                      : "px-10 py-2 border bg-green-600 text-white rounded-xl"
                  }
                  onClick={handleClose}
                >
                  {echec ? "Fermer" : "Terminer"}
                </button>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default PaymentPage;
