import React from 'react'
import { Outlet, Navigate } from "react-router-dom";


export const CorporateClientRoute = () => {
  let token = localStorage.getItem("token");
  let role = "role";


  if (token !== null && token !== undefined) {
    role = localStorage.getItem("userRole");
  } else {
    window.location.href = "/login";
  }

  return (
    <div>
      {role !== "admin" ? <Navigate to="/login" /> : <Outlet />}
    </div>
  )
}
