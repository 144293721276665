import React from 'react'

const MetiersList = () => {
  return (
    <div>
      Liste des metiers
    </div>
  )
}

export default MetiersList
