import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "../../utils/constant_api";
import axios from "axios";
import { Dialog } from "@mui/material";
import annonce from "../../Assets/annonce.jpg";
import { Link } from "react-router-dom";

const CoiffurePage = () => {
  const [coiffures, setCoiffures] = useState([]);
  const [hairstyle, setHairstyle] = useState([]);
  const conctat = +237698427176;

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (coiffure) => {
    setHairstyle(coiffure);
    setIsOpen(true);
  };

  const Close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const getCoiffures = async () => {
      axios.get(`${baseUrl}/coiffures`).then((res) => {
        setCoiffures(res.data);
      });
    };
    getCoiffures();
  }, []);
  return (
    <div className="mt-8">
      <section className="py-10 bg-gray-100">
        <div>
          <Dialog open={isOpen}>
            <div
              role="alert"
              className="rounded-xl border border-gray-100 bg-white p-4"
            >
              <div>
                <img src={annonce} alt="" />
              </div>
              <div className="mt-2 flex justify-center">
                <div className="inline-flex gap-2">
                  <Link
                    className="border-2 border-blue-600 px-3 py-2 rounded-lg text-white bg-blue-600 text-center cursor-pointer text-xs font-medium"
                    to={`/coiffure/${hairstyle.nom}`}
                  >
                    Choisir une de nos coiffeuses
                  </Link>

                  <Link
                    className="border-2 border-blue-600 px-3 py-2 rounded-lg text-white bg-blue-600 text-center cursor-pointer text-xs font-medium"
                    to={`https://wa.me/${conctat}?text=Bonjour Emploi Monde J'ai besoin des services d'une coiffeuse pour me fair la coiffure ${hairstyle.nom},  Pouvez-vous me fournir plus d'informations ?`}
                    target="_blank"
                  >
                    Laissez nous faire le choix
                  </Link>
                </div>
              </div>

              <div className="flex justify-center mt-4">
                <button
                  className="px-10 py-2 border bg-blue-600 text-white rounded-xl"
                  onClick={Close}
                >
                  Fermer
                </button>
              </div>
            </div>
          </Dialog>
        </div>
        <div className="mx-auto grid max-w-6xl  grid-cols-1 gap-6 p-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          {coiffures.map((coiffure, i) => (
            <div
              className="rounded-xl bg-white p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300"
              key={i}
            >
              <div className="flex justify-center coiffure-img overflow-hidden rounded-xl">
                <img src={coiffure?.image} alt="Hotel" />
              </div>

              <div className="mt-1 p-2">
                <h2 className="text-slate-700">{coiffure.nom}</h2>
                <p className="mt-1 text-sm text-slate-400">{coiffure.lieux}</p>
                <p className="mt-1 text-sm text-slate-400">
                  {coiffure.description}
                </p>
                <p className="my-2 text-sm text-slate-600">
                  Optenez plus de details sur la coiffure en effectuant la
                  commande
                </p>

                <div className="mt-3 flex items-end justify-between gap-2">
                  <p className="text-lg font-bold text-blue-700">
                    {coiffure.prix.split(".")[0]} FCFA
                  </p>

                  <div className="flex items-center space-x-1 rounded-lg bg-blue-700 px-2 py-1 text-white duration-100 hover:bg-blue-600">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                      />
                    </svg>

                    <h1
                      className="text-sm text-white cursor-pointer"
                      onClick={() => handleClick(coiffure)}
                    >
                      Commander
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default CoiffurePage;
